body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'NanumSquareNeo';
  font-weight: 700;
  font-style: normal;
  src: url('https://webfontworld.github.io/NanumSquareNeo/NanumSquareNeo-cBd.eot');
  src: url('https://webfontworld.github.io/NanumSquareNeo/NanumSquareNeo-cBd.eot?#iefix') format('embedded-opentype'),
       url('https://webfontworld.github.io/NanumSquareNeo/NanumSquareNeo-cBd.woff2') format('woff2'),
       url('https://webfontworld.github.io/NanumSquareNeo/NanumSquareNeo-cBd.woff') format('woff'),
       url('https://webfontworld.github.io/NanumSquareNeo/NanumSquareNeo-cBd.ttf') format("truetype");
  font-display: swap;
} 

@font-face {
  font-family: 'NanumSquareNeo800';
  font-weight: 800;
  font-style: normal;
  src: url('https://webfontworld.github.io/NanumSquareNeo/NanumSquareNeo-dEb.eot');
  src: url('https://webfontworld.github.io/NanumSquareNeo/NanumSquareNeo-dEb.eot?#iefix') format('embedded-opentype'),
       url('https://webfontworld.github.io/NanumSquareNeo/NanumSquareNeo-dEb.woff2') format('woff2'),
       url('https://webfontworld.github.io/NanumSquareNeo/NanumSquareNeo-dEb.woff') format('woff'),
       url('https://webfontworld.github.io/NanumSquareNeo/NanumSquareNeo-dEb.ttf') format("truetype");
  font-display: swap;
} 

@font-face {
  font-family: 'NanumSquareNeo900';
  font-weight: 900;
  font-style: normal;
  src: url('https://webfontworld.github.io/NanumSquareNeo/NanumSquareNeo-eHv.eot');
  src: url('https://webfontworld.github.io/NanumSquareNeo/NanumSquareNeo-eHv.eot?#iefix') format('embedded-opentype'),
       url('https://webfontworld.github.io/NanumSquareNeo/NanumSquareNeo-eHv.woff2') format('woff2'),
       url('https://webfontworld.github.io/NanumSquareNeo/NanumSquareNeo-eHv.woff') format('woff'),
       url('https://webfontworld.github.io/NanumSquareNeo/NanumSquareNeo-eHv.ttf') format("truetype");
  font-display: swap;
} 

@font-face {
  font-family: 'Chakra Petch Light';
  font-weight: 300;
  font-style: normal;
  src: url('./assets/font/ChakraPetch-Light.ttf');
  font-display: swap;
} 

@font-face {
  font-family: 'Chakra Petch Regular';
  font-weight: 400;
  font-style: normal;
  src: url('./assets/font/ChakraPetch-Regular.ttf');
  font-display: swap;
} 

.ant-form-item {
  margin-bottom: 0;
}

.even-row {
  background-color: #fafafa;
}

@media print {
  @page {
    size: A4;
    margin-top: 0;
    margin-bottom: 0;
  }
  /* body  {
    padding: 3rem;
    zoom: 65%;
  }  */

  .recordPrint, .compPrint{
    padding: 3rem;
    zoom: 60%;
  }

  .lineup, .record{
    zoom: 85%;
  }

  .notPrint {
    display: none !important;
  }
}








